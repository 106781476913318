<template>
  <div class="blurb-content">
    <img :src="property" alt="">
    <span class="bigtext">404</span>
    <span class="samleText">抱歉，你访问的页面不存在</span>
  </div>
</template>
<script>
export default {
  name: 'Error',
  data () {
    return {
      property: require('../../assets/img/error/404.png'),
    };
  },
}
</script>
<style lang="scss" scoped>
.blurb-content {
  width: 1050px;
  margin: 0 auto 156px;
  padding-top: 75px;
  position: relative;
  font-family: "Microsoft YaHei", "微软雅黑", "SimSun", "宋体";
  font-size: 16px;
  position: relative;
  img {
    max-width: 100%;
    height: auto;
    margin-left: 100px;
  }
}
.bigtext {
  font-size: 42px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: #303133;
  line-height: 59px;
  position: absolute;
  margin-left: 100px;
  margin-top: 200px;
}
.samleText {
  position: absolute;

  margin-left: 100px;
  margin-top: 300px;
  font-size: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #909399;
  line-height: 17px;
}
</style>